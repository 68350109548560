@import "~antd/dist/antd.css";

body {
  color: #37383c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-variant-ligatures: none;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}
#root {
  height: 100vh;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
a,
a:hover {
  text-decoration: none;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: linear-gradient(to right, #e05c04, #e05c04)!important;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #e05c04, #e05c04)!important;
}
.ant-spin {
  color: #e05c04!important;
}
.ant-spin-dot-item {
  background-color: #e05c04!important;
}
.ant-menu-submenu-title:hover {
  color: #e05c04!important;
}
.ant-menu-item-selected  {
  color: #e05c04!important;
}
.ant-menu-submenu-selected {
  color: #e05c04!important;
}
.ant-menu-item::after {
  border-right: 3px solid #e05c04!important;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, 
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #e05c04!important;
}
a {
  color: #e05c04;
  transition: color 0.2s linear;
  cursor: pointer;
}
.ant-btn.ant-btn-primary {
  background: #e05c04;
  border-color: #ff7d26;
}
.ant-btn.ant-btn-primary:focus {
  background: #ff4d4f;
  border-color: #ff7d26;
}
.ant-btn.ant-btn-primary:hover {
  background: #ff4d4f;
  border-color: #ff7d26;
}
.uploader {
  width: 128px;
  height: 128px;
}

.uploader > div {
  width: 128px !important;
  height: 128px !important;
}

.user-manual-editor {
  border: 1px solid #d9d9d9;
  min-height: 200px;
  padding: 0 10px;
}